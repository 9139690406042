import React from "react"
import { Link, withPrefix } from "gatsby"

import logo from "../../images/logos/logo-horizontal-blue-wording.png"
import primeLogo from "../../images/logos/DNA-Explorer-Prime-white.png"
import alacarteLogo from "../../images/logos/DNA-Explorer-Personal-white.png"
import carrierLogo from "../../images/logos/DNA-Explorer-Carrier-white.png"
import oncoLogo from "../../images/logos/DNA-Explorer-Onco-white.png"

const Footer = () => (
  <footer className="main-footer bg-dark text-white">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <Link to="/" className="brand">
            <img src={withPrefix(logo)} alt="Advanx Health" height="80px" />
          </Link>
          <p>Discover your DNA. Personalise your lifestyle.</p>
        </div>
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-3 col-6 order-1 order-lg-1">
              <p className="footer-title">Advanx Health</p>
              <ul className="contact-info list-unstyled text-white">
                <li>
                  <Link to="/about/">About Us</Link>
                </li>
                <li>
                  <Link to="/how-it-works/">How It Works</Link>
                </li>
                <li>
                  <a href="https://blog.advanxhealth.com/">Blog</a>
                </li>
                <li>
                  <Link to="/discover-genetics/">Discover Genetics</Link>
                </li>
                <li>
                  <Link to="/support/">Customer Care & Support</Link>
                </li>
                <li>
                  <Link to="/press-and-media/">Press & Media</Link>
                </li>
                <li>
                  <Link to="/partners/">Partner with Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 order-3 order-lg-4">
              <p className="footer-title">Legal</p>
              <ul className="contact-info list-unstyled text-white">
                <li>
                  <Link to="/terms/">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/privacy/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/refunds/">Refunds Policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 order-2 order-lg-2">
              <p className="footer-title">Products</p>
              <ul className="contact-info list-unstyled text-white">
                {/*
                <li className="pr-4"><Link to={ withPrefix("/products/dna-explorer-essential") }><img className="img-fluid" src={essentialLogo} alt="DNA Explorer Essential"/></Link></li>
                <li className="pr-4"><Link to={ withPrefix("/products/dna-explorer-health360") }><img className="img-fluid" src={health360Logo} alt="DNA Explorer Health360"/></Link></li>
                */}
                <li className="pr-4">
                  <Link to={withPrefix("/products/dna-explorer-personal")}>
                    <img
                      className="img-fluid"
                      src={alacarteLogo}
                      alt="DNA Explorer"
                    />
                  </Link>
                </li>
                <li className="pr-4">
                  <Link to={withPrefix("/products/dna-explorer-prime")}>
                    <img
                      className="img-fluid"
                      src={primeLogo}
                      alt="DNA Explorer Prime"
                    />
                  </Link>
                </li>
                <li className="pr-4">
                  <Link to={withPrefix("/products/dna-explorer-carrier")}>
                    <img
                      className="img-fluid"
                      src={carrierLogo}
                      alt="DNA Explorer Carrier"
                    />
                  </Link>
                </li>
                <li className="pr-4">
                  <Link to={withPrefix("/products/dna-explorer-onco")}>
                    <img
                      className="img-fluid"
                      src={oncoLogo}
                      alt="DNA Explorer Onco"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 order-4 order-lg-3">
              <p className="footer-title">Contact Us</p>
              <ul className="contact-info list-unstyled text-white">
                <li>
                  <a href="mailto:hello@advanxhealth.com">
                    <i className="fa fa-envelope"></i> Email
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/advanxhealthmy">
                    <i className="fa fa-facebook"></i> Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/advanxhealth/">
                    <i className="fa fa-instagram"></i> Instagram
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCPPy1GZ_ntKE4BGskZaPd3A">
                    <i className="fa fa-youtube"></i> Youtube
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/advanx-health">
                    <i className="fa fa-linkedin"></i> LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <small
        className="text-light"
        style={{ fontSize: ".6rem", fontWeight: "normal" }}
      >
        Copyright © 2020 Ubisana Innovations Sdn Bhd (1268332-M). All rights
        reserved.
      </small>
    </div>
  </footer>
)

export default Footer
