import React from "react"
import { Link, withPrefix } from "gatsby"
import logo from "../../images/logos/logo.png"
import logoNoWording from "../../images/logos/logo-no-wording.png"
import API from "../../services/api"

export default class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMobileDropdown: false,
      showProductDropdown: false,
      showReportsDropdown: false,
      showInfosDropdown: false,
      showAboutDropdown: false,
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      var cart_id = JSON.parse(localStorage.getItem("advanx_cart_id"))
      cart_id === null || cart_id === "[]"
        ? API.createCart()
        : API.getCart(cart_id[0])
      if (localStorage.getItem("advanx_line_items") === null) {
        localStorage.setItem("advanx_line_items", "[]")
      }

      if (localStorage.getItem("advanx_items_count") === null) {
        localStorage.setItem("advanx_items_count", 0)
      }

      if (localStorage.getItem("advanx_processing_fees") === null) {
        localStorage.setItem("advanx_processing_fees", 0)
      }

      window.$itemCount = parseInt(
        JSON.parse(localStorage.getItem("advanx_items_count"))
      )
    }
  }

  handleMobileChange = () => {
    this.setState({ showMobileDropdown: !this.state.showMobileDropdown })
  }

  handleProductChange = () => {
    this.setState({ showProductDropdown: !this.state.showProductDropdown })
  }

  handleReportsChange = () => {
    this.setState({ showReportsDropdown: !this.state.showReportsDropdown })
  }

  handleAboutChange = () => {
    this.setState({ showAboutDropdown: !this.state.showAboutDropdown })
  }

  handleInfosChange = () => {
    this.setState({ showInfosDropdown: !this.state.showInfosDropdown })
  }

  render() {
    var itemCount = 0
    if (typeof window !== "undefined") {
      itemCount = window.$itemCount
    }
    let mobileClasses = "collapse navbar-collapse"
    let productClasses = "dropdown-menu"
    let reportsClasses = "dropdown-menu"
    let aboutClasses = "dropdown-menu"
    let infosClasses = "dropdown-menu dropdown-menu-right"
    if (this.state.showMobileDropdown) {
      mobileClasses = "collapse navbar-collapse show"
    }
    if (this.state.showProductDropdown) {
      productClasses = "dropdown-menu show bg-light"
    }
    if (this.state.showReportsDropdown) {
      reportsClasses = "dropdown-menu show bg-light"
    }
    if (this.state.showInfosDropdown) {
      infosClasses = "dropdown-menu dropdown-menu-right show bg-light"
    }
    if (this.state.showAboutDropdown) {
      aboutClasses = "dropdown-menu show bg-light"
    }

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-white static-top">
          <div className="logo">
            <Link to="/" className="navbar-brand">
              <img
                src={logo}
                alt="Advanx Health"
                className="d-none d-sm-inline"
                alt="Advanx Health"
              />
              <img
                src={logoNoWording}
                alt="Advanx Health"
                className="d-sm-none"
                alt="Advanx Health"
              />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.handleMobileChange}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={mobileClasses} id="navbarSupportedContent">
            <ul className="navbar-nav nav-center ml-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdownAbout"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={this.handleAboutChange}
                >
                  About
                </a>
                <div
                  className={aboutClasses}
                  aria-labelledby="dropdownAbout"
                  style={{ border: "none" }}
                >
                  <Link className="dropdown-item" to="/about">
                    About Us
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/advanx-lab")}
                  >
                    Advanx Lab
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdownProducts"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={this.handleProductChange}
                >
                  Products
                </a>
                <div
                  className={productClasses}
                  aria-labelledby="dropdownProducts"
                  style={{ border: "none" }}
                >
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/products/dna-explorer-personal")}
                  >
                    <img
                      className="img-fluid"
                      style={{ maxWidth: "10rem" }}
                      src={withPrefix("img/logo/DNA-Explorer-Personal.png")}
                    />
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/products/dna-explorer-prime")}
                  >
                    <img
                      className="img-fluid"
                      style={{ maxWidth: "10rem" }}
                      src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
                    />
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/products/dna-explorer-carrier")}
                  >
                    <img
                      className="img-fluid"
                      style={{ maxWidth: "10rem" }}
                      src={withPrefix("img/logo/DNA-Explorer-Carrier.png")}
                    />
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/products/dna-explorer-onco")}
                  >
                    <img
                      className="img-fluid"
                      style={{ maxWidth: "10rem" }}
                      src={withPrefix("img/logo/DNA-Explorer-Onco.png")}
                    />
                    &nbsp;&nbsp;
                    <span className="badge badge-warning">NEW</span>
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdownReports"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={this.handleReportsChange}
                >
                  Reports
                </a>
                <div
                  className={reportsClasses}
                  aria-labelledby="dropdownReports"
                  style={{ border: "none" }}
                >
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/reports/nutrition")}
                  >
                    Nutrition & Diet
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/reports/fitness")}
                  >
                    Fitness
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/reports/skin")}
                  >
                    Skin
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/reports/allergy-sensitivity")}
                  >
                    Allergy & Sensitivity
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/reports/weight-management")}
                  >
                    Weight Management
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/reports/inner-potential")}
                  >
                    Inner Potential
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={withPrefix("/reports/health")}
                  >
                    Health Risks
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={withPrefix("/blog")}>
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://app.advanxhealth.com/kit/id"
                >
                  Register Kit
                </a>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to={withPrefix("/covid-19-rapid-test")}>
                  COVID-19 Test
                </Link>
              </li> */}
            </ul>
            <ul className="navbar-nav ml-auto nav-right">
              <li
                className="nav-item"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                <a
                  className="nav-link"
                  href="/cart"
                  style={{ position: "relative" }}
                >
                  <sup>
                    <span className="badge badge-success">{itemCount}</span>
                  </sup>
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-cart3"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                </a>
              </li>
              <li
                className="nav-item"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                <a
                  className="nav-link"
                  href="https://app.advanxhealth.com/sign_in"
                >
                  <i className="fa fa-user-o" aria-hidden="true"></i>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdownInfos"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={this.handleInfosChange}
                >
                  <i className="fa fa-question-circle-o"></i>
                </a>
                <div
                  className={infosClasses}
                  aria-labelledby="dropdownInfos"
                  style={{ border: "none" }}
                >
                  <Link className="dropdown-item" to={withPrefix("/faq")}>
                    Frequently Asked Question (F.A.Q)
                  </Link>
                  <Link className="dropdown-item" to={withPrefix("/support")}>
                    Customer Care & Support
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}
