import axios from "axios"

var API_URL = ""
var BASE_URL = ""

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  API_URL = "http://localhost:5000/api/v1/publics"
  BASE_URL = "http://localhost:5000"
} else {
  API_URL = "https://app.advanxhealth.com/api/v1/publics"
  BASE_URL = "https://app.advanxhealth.com"
}

export default {
  // Get cart sessions
  getCart: cart_id => {
    return axios.get(API_URL + "/carts/" + cart_id)
  },

  // Create new cart for new session
  createCart: () => {
    return axios
      .post(API_URL + "/carts")
      .then(function(response) {
        var resp_arr = [response.data.id]
        localStorage.setItem("advanx_cart_id", JSON.stringify(resp_arr))
      })
      .catch(function(error) {
        console.log(error)
      })
  },

  // Create new cart for add more person
  createAddMoreCart: parent_id => {
    return axios.post(API_URL + "/carts", { parent_id: parent_id })
  },

  removeAddMoreCart: cart_id => {
    return axios.delete(API_URL + "/carts/" + cart_id)
  },

  // Add item to cart
  addItem: (cart_id, package_id, price) => {
    return axios
      .post(API_URL + "/carts/items", {
        package_id: package_id,
        price: price,
        id: cart_id,
      })
      .then(function(response) {
        alert("Successfull added product to cart.")
      })
      .catch(function(error) {
        console.log(error)
      })
  },

  // Add ala carte item to cart
  addAlaCarte: (cart_id, product_id, category_name) => {
    return axios
      .post(API_URL + "/carts/items", {
        id: cart_id,
        product_id: product_id,
        category_name: category_name,
      })
      .then(function(response) {
        alert("Successfull added product to cart.")
      })
      .catch(function(error) {
        console.log(error)
      })
  },

  // Add more item
  addAddonItem: (cart_id, product_id) => {
    return axios.post(API_URL + "/carts/items", {
      id: cart_id,
      product_id: product_id,
      add_more: true,
    })
  },

  // Apply Discount code
  applyDiscount: (cart_id, promotion) => {
    return axios.post(API_URL + "/discounts", {
      cart_id: cart_id[0],
      promotion: promotion,
    })
  },

  // Get current cart discount if exists
  getDiscount: cart_id => {
    return axios.get(API_URL + "/discounts/" + cart_id)
  },

  // Remove item in cart
  removeItem: (id, type) => {
    return axios.delete(API_URL + "/carts/item/" + id + "/" + type)
  },

  // Get total number of items in cart
  getCartTotal: id => {
    return axios.get(API_URL + "/carts/" + id + "/total")
  },

  // Get subtotal & processing fees
  getPrice: id => {
    return axios.get(API_URL + "/carts/" + id + "/price")
  },

  // Send checkout data

  createCheckout: (
    firstname,
    lastname,
    phone,
    email,
    street,
    additional_street,
    city,
    postcode,
    state,
    country,
    cart_id,
    firstname_arr,
    lastname_arr,
    email_arr,
    total_price
  ) => {
    return axios
      .post(API_URL + "/checkout", {
        firstname,
        lastname,
        phone,
        email,
        street,
        additional_street,
        city,
        postcode,
        state,
        country,
        cart_id,
        firstname_arr,
        lastname_arr,
        email_arr,
      })
      .then(function(response) {
        localStorage.setItem("cart_total_price", total_price)
        window.location.href = "/cart-overview"
      })
  },

  // Get featured blog post
  getBlogPost: filter => {
    if (filter) {
      return axios.get(
        "https://blog.advanxhealth.com/wp-json/wp/v2/posts?filter[cat]=" +
          filter +
          "?per_page=100"
      )
    } else {
      return axios.get(
        "https://blog.advanxhealth.com/wp-json/wp/v2/posts?per_page=100"
      )
    }
  },

  // Get category
  getPostCategories: id => {
    if (id) {
      return axios.get(
        `https://blog.advanxhealth.com/wp-json/wp/v2/categories/${id}`
      )
    } else {
      return axios.get(`https://blog.advanxhealth.com/wp-json/wp/v2/categories`)
    }
  },

  // Get blog post content
  getPost: slug => {
    return axios.get(
      "https://blog.advanxhealth.com/wp-json/wp/v2/posts?slug=" + slug
    )
  },
}
